import React, { useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CustomButton } from "./CustomButton";
import dayjs from "dayjs";

export const CustomDropdown = ({
  value,
  onChange,
  options = [],
  placeHolder,
  icon = null,
  date = false,
  name,
  error,
  helperText,
  label,
  disableDate,
  disableFuture,
  disablePastDate,
  minDate,
  disabled,
  handleInputChange,
  removeFilter
}) => {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleChange = (_, newValue) => {
    if (newValue !== null) {
      onChange(name, newValue);
    }
  };

  const handleDateChange = (newValue) => {
    onChange(name, newValue);
  };

  const dateArr = {
    Su: "SUN",
    Mo: "MON",
    Tu: "TUE",
    We: "WED",
    Th: "THU",
    Fr: "FRI",
    Sa: "SAT",
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <FormControl className="custom-select" fullWidth>
        {date ? (<Box display={"flex"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled={disableDate}
              disableFuture={disableFuture}
              disablePast={disablePastDate}
              minDate={minDate ? dayjs(minDate) : ""}
              format={"MM-DD-YYYY"}
              className="custom-date-select"
              onChange={handleDateChange}
              value={dayjs(value)}
              dayOfWeekFormatter={(day) => dateArr[day]}
              slotProps={{
                textField: {
                  readOnly: true,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label} // Set the label text
                  placeholder={placeHolder} // Set the placeholder text
                  onKeyDown={onKeyDown}
                />
              )}
            />
          </LocalizationProvider>
          {name === "exploreFilterDate" && value ? <img src={icon} onClick={removeFilter} className="explore-filter-date-picker-close" alt="" /> : null}
        </Box>
        ) : (
          <Autocomplete
            disabled={disabled || false}
            className="dropdown-select"
            value={value || ""}
            disablePortal={true}
            freeSolo={false}
            onChange={handleChange}
            open={open}
            name={name}
            onClose={handleClose}
            onOpen={handleOpen}
            options={options}
            onInputChange={
              handleInputChange
            }
            selectOnFocus={false}
            getOptionLabel={(option) => {
              return option?.label ? option?.label : typeof option === 'string' ? option : "";
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <CustomButton
                      disabled={disabled || false}
                      className="arrow-btn"
                      color="#E35205"
                      icon={icon}
                      onClick={removeFilter ? removeFilter : handleOpen}
                      backgroundColor="#E7EFF9"
                    />
                  ),
                }}
                placeholder={placeHolder ?? ""}
                variant="outlined"
              />
            )}
          />
        )}
        <Backdrop open={open} style={{ zIndex: 1, background: "#00308780" }} />
      </FormControl>
      {error && (
        <FormHelperText className={"Mui-error"} sx={{ ml: 3 }}>{helperText}</FormHelperText>
      )}
      <style jsx>
        {`
        @media screen and (max-width : 600px){
          .custom-select .custom-date-select .MuiButtonBase-root.MuiIconButton-root {
            display: ${name === "exploreFilterDate" && value ? "none !important" : "flex"};
          }
        }
        `}
      </style>
    </>
  );
};

export default CustomDropdown;

import { API_URL_3 } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";

const Base_URL = API_URL_3;
const locationId = encodeURIComponent(getLocalData("locationId"));
export const GetAllTrainersAvailability = async (payload) => {
  let obj = {
    locationId,
    ...payload,
  };
  try {
    return await ApiServices.post(
      `${Base_URL}schedule/getTrainerAvailability`,
      obj
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllPets = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}schedule/getAllPets?clientId=${encodeURIComponent(
        getLocalData("clientId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getServiceCategories = async (type) => {
  try {
    return await ApiServices.get(
      `${Base_URL}schedule/getAllServiceCategories?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&serviceType=${type}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllRooms = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}schedule/getAllRooms?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getServiceByCategory = async (categoryId) => {
  const encodedCategoryID = encodeURIComponent(categoryId);
  try {
    return await ApiServices.get(
      `${Base_URL}services/getAllServices?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&categoryId=${encodedCategoryID}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getValidatePetEligibility = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}schedule/validatePet`, payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
}

export const bookAppointment = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}schedule/addAppointment`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const updateAppointment = async (payload, AppointmentId) => {
  const encodedAppointmentId = encodeURIComponent(AppointmentId);
  try {
    return await ApiServices.put(
      `${Base_URL}schedule/bookAppointment?appointmentId=${encodedAppointmentId}`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const cancelSchedule = async (payload) => {
  try {
    return await ApiServices.put(
      `${Base_URL}schedule/changeStatus`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const cancelAppointment = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}schedule/cancelAppointment`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import close from "../../assets/images/redClose.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CustomButton,
  CustomDropdown,
  CustomLoader,
} from "../../Components/Common";
import { CustomDialogue, ProfileModals } from "../../Components/Modals";
import {
  GetAllPets,
  GetCartItems,
  RemoveCartItems,
  getClientProfileProgress,
} from "../../Services/APIs";
import {
  ApplyGiftCardDiscount,
  CalculateFinalOrder,
  GetMemberShipDiscounts,
  GetPromocodeCetails,
  ValidateGiftCard,
} from "../../Services/APIs/shop";
import { getLocalData, setLocalData } from "../../Utils";
import cartStep1 from "../../assets/images/cart-step-1.svg";
import cartStep2 from "../../assets/images/cart-step-2.svg";
import { ExploreContext } from "../../App";
import RegisterPop from "../../Components/Common/RegisterPop";
import { useNavigate } from "react-router-dom";
const downArrow = require("../../assets/images/dropdownArrow.svg").default;
export default function Cart({
  handleNext,
  setShopCheckoutTotal,
  summary,
  setSummary,
  setSelectedPetCheckout,
}) {
  const [open, setOpen] = useState(false);
  const [getCartData, setCartData] = useState([]);
  const [discountDetails, setDiscountDetails] = useState({});
  const [error, setError] = useState({
    promocodeErr: "",
    discountErr: "",
    giftCodeErr: "",
  });

  const [cartPopup, setCartPopup] = useState({ show: false, type: "" });
  const [calculatedOrderDetails, setCalculatedOrderDetails] = useState();
  const [loader, setLoader] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [clientProgess, setClientProgess] = useState({});
  const [clientProgressPopup, setClientProgressPopup] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [giftCode, setGiftCode] = useState("");
  const [selectedPet, setSelectedPet] = useState({});
  const [petList, setPetList] = useState([]);
  const [isDogSelect, setIsDogSelect] = useState("");
  const [giftCodeFocused, setGiftCodeFocused] = useState(false);
  const [isValidToCheckout, setIsValidToCheckout] = useState(false);
  const { isGuest } = useContext(ExploreContext);
  const navigate = useNavigate();

  useEffect(() => {
    getCartDetails();
    handleGetPetsList();
  }, []);

  // useEffect(() => {
  //   setSummary(false);
  // }, []);

  useEffect(() => {
    getClientProfileProgressDetails();
  }, []);

  // getclient-progress
  const getClientProfileProgressDetails = () => {
    getClientProfileProgress().then((response) => {
      if (response?.statusCode === 200) {
        const data = response?.data;
        setClientProgess(data?.clientProfileProgress);
      }
    });
  };

  const handleCheckoutClick = () => {
    if (Number(clientProgess) < 1) {
      setClientProgressPopup(true);
    } else {
      if (!Object.keys(selectedPet).length) {
        setIsDogSelect("Please select your pet to continue.");
        window?.scrollTo(0, 0);
      } else {
        setIsDogSelect("");
        setOpen(true);
      }
    }
  };

  //get cart item list
  const getCartDetails = () => {
    setLoader(true);
    GetCartItems().then((response) => {
      if (response?.statusCode === 200) {
        setLocalData("cartID", response?.data?.Items[0]?.sortKey);
        const result = response?.data.Items;
        setCartData(result);
        if (summary) {
          GetApplyMembershipDiscount(result);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  //remove cart item list
  const removeCartDetails = (item) => {
    let locationID = getLocalData("locationId");
    let clientID = getLocalData("clientId");
    RemoveCartItems(item.itemId, locationID, clientID).then((response) => {
      setLoader(true);
      if (response.statusCode === 200) {
        setCartPopup({ ...cartPopup, show: true, type: "CartDelete" });
        getCartDetails();
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  // api for getting GetApplyMembershipDiscount
  const GetApplyMembershipDiscount = async (result) => {
    setLoader(true);
    let locationID = getLocalData("locationId");
    let clientID = getLocalData("clientId");
    let userData = getLocalData("user_detail");
    let payloadData = {
      locationId: locationID,
      clientId: clientID,
      clientName: getLocalData("user_detail") ? JSON.parse(userData)?.name : "",
      items: (getCartData && getCartData[0]?.items) || result[0]?.items,
      createdBy: getLocalData("clientId"),
    };

    const response = await GetMemberShipDiscounts(payloadData);
    if (response?.statusCode === 200) {
      setLoader(false);
      setDiscountDetails(response?.data);
      setLocalData("queueId", response?.data?.queueId);
      setLocalData("createdBy", response?.data?.createdBy);
      handleCalculateFinalOrder(response?.data);
    } else {
      setLoader(false);
    }
  };

  const handlePromoCode = async (e) => {
    const { id, value } = e.target;
    setPromoCode(value?.toUpperCase());
    const requestData = {
      locationId: getLocalData("locationId"),
      promoCode: value?.toUpperCase(),
      queueId: discountDetails?.queueId,
    };
    const response = await GetPromocodeCetails(requestData);
    if (response?.statusCode === 200 && response?.data?.promoCode) {
      setLoader(true);
      setCartPopup({ type: "promoCode", show: true });
      handleCalculateFinalOrder();
      setError({
        ...error,
        promocodeErr: "",
      });
    } else if (response?.statusCode === 200 && !response?.data?.Items?.length) {
      setError({
        ...error,
        promocodeErr: response?.message && response?.message,
      });
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleGiftCode = async (e) => {
    setGiftCode(e.target.value?.toUpperCase()?.slice(0, 12));
    if (e?.target?.value) {
      const payload = {
        locationId: getLocalData("locationId"),
        giftCard: e.target.value?.toUpperCase()?.slice(0, 12),
      };

      const response = await ValidateGiftCard(payload);
      if (response?.statusCode === 200) {
        if (response?.data?.result?.Items?.length !== 0) {
          setError({ ...error, giftCodeErr: "" });
          handleApplyGiftCardDiscount(response?.data?.result?.Items[0]);
        } else {
          if (e?.target?.value?.length === 12) {
            setError({ ...error, giftCodeErr: response?.message });
          } else {
            setError(
              e?.target?.value?.length < 6 || e?.target?.value?.length > 12
                ? {
                  ...error,
                  giftCodeErr: "Gift card should have minimum 6 and maximum 12 characters long.",
                }
                : { ...error, giftCodeErr: response?.message }
            )
          }
        }
      }
    } else {
      setError({ ...error, giftCodeErr: "" });
    }
  };

  const handleApplyGiftCardDiscount = async (item) => {
    const payload = {
      locationId: getLocalData("locationId"),
      giftCardId: item?.sortKey,
      queueId: getLocalData("queueId"),
    };
    const response = await ApplyGiftCardDiscount(payload);
    if (
      response?.statusCode === 200 &&
      response?.message ===
      "Your gift card balance is 0. Please recharge your gift card to proceed."
    ) {
      setError({ ...error, giftCodeErr: response?.message });
    } else if (
      response?.statusCode === 200 &&
      (response?.message === "Gift card applied successfully" || response?.message === "Gift card number is available")
    ) {
      setCartPopup({ type: "giftCard", show: true });
      handleCalculateFinalOrder({ queueId: item?.queueId });
    }
  };

  const handleCalculateFinalOrder = async (details) => {
    setLoader(true);
    let queueIdLocal = getLocalData("queueId");
    const requestData = {
      clientId: getLocalData("clientId"),
      clientName: JSON.parse(getLocalData("user_detail")).name,
      createdByName: JSON.parse(getLocalData("user_detail")).name,
      createdBy: getLocalData("clientId"),
      franchiseeId: getLocalData("franchiseeId"),
      franchiseeName: getLocalData("franchiseeName"),
      guestClient: details?.guestClient || false,
      locationId: getLocalData("locationId"),
      locationName: getLocalData("locationName"),
      queueId: details?.queueId || queueIdLocal,
      petId: selectedPet?.dog && selectedPet?.dog?.value?.sortKey,
      petName:
        selectedPet?.dog?.value?.firstName &&
        selectedPet?.dog?.value?.firstName +
        " " +
        selectedPet?.dog?.value?.lastName,
    };
    const response = await CalculateFinalOrder({ ...requestData });
    if (response?.statusCode === 200) {
      setCalculatedOrderDetails(response?.data);
      setShopCheckoutTotal(response?.data);
      setSummary(true);
      setLoader(false);
      setIsValidToCheckout(true);
    } else {
      setTimeout(() => {
        ReCalculateFinalOrder(details);
      }, 500);
    }
  };

  const ReCalculateFinalOrder = async (details) => {
    setLoader(true);
    let queueIdLocal = getLocalData("queueId");
    const requestData = {
      clientId: getLocalData("clientId"),
      clientName: JSON.parse(getLocalData("user_detail")).name,
      createdByName: JSON.parse(getLocalData("user_detail")).name,
      createdBy: getLocalData("clientId"),
      franchiseeId: getLocalData("franchiseeId"),
      franchiseeName: getLocalData("franchiseeName"),
      guestClient: details?.guestClient || false,
      locationId: getLocalData("locationId"),
      locationName: getLocalData("locationName"),
      queueId: details?.queueId || queueIdLocal,
      petId: selectedPet?.dog && selectedPet?.dog?.value?.sortKey,
      petName:
        selectedPet?.dog?.value?.firstName &&
        selectedPet?.dog?.value?.firstName +
        " " +
        selectedPet?.dog?.value?.lastName,
    };
    const response = await CalculateFinalOrder({ ...requestData });
    if (response?.statusCode === 200) {
      setCalculatedOrderDetails(response?.data);
      setShopCheckoutTotal(response?.data);
      setSummary(true);
      setLoader(false);
      setIsValidToCheckout(true);
    } else {
      setLoader(false);
      setIsValidToCheckout(false);
      setCartPopup({ ...cartPopup, show: true, type: "Failed" });
    }
  };

  function capitalizeWords(str) {
    const words = str?.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  const handleGetPetsList = async () => {
    const response = await GetAllPets();
    if (response?.statusCode === 200) {
      const data = response?.data?.Items?.map((item) => ({
        label:
          capitalizeWords(item.firstName) ||
          "" + capitalizeWords(item.lastName) ||
          "",
        value: item,
      }));
      setPetList(data);
    }
  };

  const handleDropdownChange = (name, value) => {
    setIsDogSelect("");
    setSelectedPet({ ...selectedPet, [name]: value });
    setSelectedPetCheckout({ ...selectedPet, [name]: value });
  };

  return (
    <>
      <CustomLoader IsLoading={loader} />
      <Box className="cart_steps_wrapper" py={{ sm: 4 }}>
        <Box className="cart_steps_inner" textAlign={"center"}>
          <img src={summary ? cartStep2 : cartStep1} alt="steps" />
          <Box className="discount_text" mt={1}>
            {" "}
            <Typography mr={5} className="discount_text_blue">
              Added Item
            </Typography>{" "}
            <Typography
              className={summary ? "discount_text_blue" : "discount_text_dark"}
            >
              Discount
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {!summary ? (
          <Box className="cartWrap">
            <Box
              className="template-list-main-cart  template-list-main"
              sx={{ width: "100%" }}
            >
              <Box className="list-section">
                {getCartData &&
                  getCartData[0]?.items &&
                  getCartData[0]?.items?.length ? (
                  getCartData[0]?.items?.map((item, index) => (
                    <Box className="main-schedule-box" key={index}>
                      <Box className="class-section-boxx">
                        <Box className="class-section">
                          <Box
                            className="item"
                            key={index}
                            p={{ xs: "9px 18px", sm: "9px 30px" }}
                          >
                            <Box
                              sx={{
                                wordBreak: "break-word",
                                boxSizing: "border-box",
                              }}
                              p={{ xs: "0px 2px" }}
                            >
                              <Typography
                                maxWidth={{ xs: "90%", sm: "100%", md: "100%" }}
                              >
                                {item?.itemName}
                              </Typography>
                              <Typography>{item?.itemType}</Typography>
                            </Box>
                            <Box>
                              <Typography as={"span"} mr={2}>
                                {" "}
                                ${item?.price.toFixed(2) || 0}
                              </Typography>
                              <Typography>
                                {" "}
                                {item?.numberOfSessions &&
                                  `${item?.numberOfSessions || 0} Session`}
                              </Typography>
                              <Button>
                                <img
                                  src={close}
                                  alt="close"
                                  onClick={() => removeCartDetails(item)}
                                />{" "}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography>
                    {" "}
                    Cart is empty please add something to purchase.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box className="promocard-cart">
              <Grid container>
                <Grid xs={12} md={6}>
                  <Box
                    className="checkProcess cart_select_dog"
                    sx={{ mt: 3, width: "100%" }}
                  >
                    <CustomDropdown
                      placeHolder="Select Your Pet"
                      value={selectedPet.dog}
                      onChange={handleDropdownChange}
                      options={petList}
                      icon={downArrow}
                      fullWidth
                      name={"dog"}
                      error={isDogSelect}
                      helperText={
                        <span style={{ marginLeft: "15px" }}>
                          {" "}
                          {isDogSelect}{" "}
                        </span>
                      }
                    />
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box
                    className="checkProcess"
                    sx={{ mt: 3, width: "100%" }}
                    ml={{ md: 2 }}
                  >
                    <Box className="input-item-wrap">
                      <Box className="codeBox">
                        <Typography>Promo Code</Typography>
                        <TextField
                          id="Add-code"
                          label="Add code"
                          variant="standard"
                          value={promoCode}
                          onChange={(e) => handlePromoCode(e)}
                        // helperText={error?.promocodeErr}
                        // error={error?.promocodeErr}
                        />
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginLeft: "15px",
                      }}
                    >
                      {error?.promocodeErr}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box className="checkProcess" sx={{ mt: 3, width: "100%" }}>
                    <Box className="input-item-wrap">
                      <Box className="codeBox">
                        <Typography>Gift Card</Typography>
                        <TextField
                          id="gift-card"
                          onFocus={() => {
                            setGiftCodeFocused(true);
                          }}
                          onBlur={() => {
                            setGiftCodeFocused(giftCode?.length ? true : false);
                            setError(
                              giftCode?.length
                                ? { ...error }
                                : { ...error, giftCodeErr: "" }
                            );
                          }}
                          label={!giftCodeFocused ? "XXX XXX XXX XXX" : ""}
                          variant="standard"
                          value={giftCode}
                          onChange={(e) => handleGiftCode(e)}
                        />
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginLeft: "15px",
                      }}
                    >
                      {error?.giftCodeErr}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box
                    className="checkProcess"
                    sx={{ mt: { xs: 1, md: 3 }, width: "100%" }}
                    ml={{ md: 2 }}
                  >
                    <Box className="input-item-wrap">
                      <Box className="codeBox">
                        <Typography>Total Discount</Typography>
                        <TextField
                          id="Discount"
                          value={`$${Number(
                            calculatedOrderDetails?.totalSquareDiscountAmountInDollar ||
                            0
                          )?.toFixed(2)}`}
                          variant="standard"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid xs={12} md={6}>
                <Box className="checkProcess-cart" sx={{ mt: 3 }}>
                  <Box className="summaryBox">
                    <Typography>Order Summary</Typography>
                    <Box>
                      <Typography>Order</Typography>
                      <Typography>
                        $
                        {Number(
                          calculatedOrderDetails?.totalSquareAmountWithoutTaxInDollar +
                          calculatedOrderDetails?.totalSquareDiscountAmountInDollar ||
                          0
                        )?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>Tax</Typography>
                      <Typography>
                        $
                        {Number(
                          calculatedOrderDetails?.totalSquareTaxAmountInDollar ||
                          0
                        )?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>Total</Typography>
                      <Typography>
                        $
                        {Number(
                          calculatedOrderDetails?.totalSquareAmountWithTaxInDollar ||
                          0
                        )?.toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {summary ? (
        <Box className="footer-section" sx={{ marginTop: 18 }}>
          <CustomButton
            className="book-btn"
            title={"CHECK OUT"}
            color="#fff"
            disabled={!isValidToCheckout}
            backgroundColor="#32B2AC"
            iconJsx={<ChevronRightIcon />}
            fullWidth
            onClick={handleCheckoutClick}
          />
        </Box>
      ) : (
        <Box
          className="footer-section"
          sx={{ marginTop: 18, width: { xs: "100%", sm: "50%" } }}
        >
          <CustomButton
            className="book-btn"
            title={"Next"}
            disabled={
              !(
                getCartData &&
                getCartData[0]?.items &&
                getCartData[0]?.items?.length
              )
            }
            color="#fff"
            backgroundColor="#32B2AC"
            iconJsx={<ChevronRightIcon />}
            fullWidth
            onClick={() =>
              isGuest ? setShowRegister(true) : GetApplyMembershipDiscount()
            }
          />
        </Box>
      )}
      <CustomDialogue
        type="cart"
        open={open}
        className={"checkoutModal"}
        handleClose={() => setOpen(false)}
        fullWidth
        handleNext={() => {
          handleNext();
          setOpen(false);
        }}
      />

      {/* progressCheckout */}
      <CustomDialogue
        type="progressCheckout"
        open={clientProgressPopup}
        className={"checkoutModal"}
        handleClose={() => setClientProgressPopup(false)}
        fullWidth
        handleNext={() => {
          setClientProgressPopup(false);
          navigate("/profile", { state: { redirect: "edit" } });
        }}
      />
      <ProfileModals
        open={cartPopup?.show}
        type={cartPopup?.type}
        handleActionBtn={() => setCartPopup({ ...cartPopup, show: false })}
      />
      <RegisterPop
        open={showRegister}
        setOpen={setShowRegister}
        messege="In order to add this service, you will have to create your profile, Do you want to create now ?"
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import YourSchedule from "../Common/YourSchedule";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CustomButton, CustomDropdown } from "../Common";
import { ExploreContext, LocationDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { ApiServices } from "../../Services/ApiServices";
import { API_URL_2 } from "../../Utils/constants";
import { getLocalData, setLocalData } from "../../Utils";
import { GetLocationBasedStates, GetLocationsList, GetOrderHistory } from "../../Services/APIs";
const downArrow = require("../../assets/images/dropdownArrow.svg").default;

export default function LocationSidebar({
  handleClose,
  clientDetail,
  updateClientDataOnSetLocation,
  setActiveSteps,
  appointmentOptions,
  IsError,
  error,
  handleSelectedItems,
  setIsYourSchedule,
}) {
  const navigate = useNavigate();
  const Base_URL = API_URL_2;
  let clientId = getLocalData("clientId");

  const { isGuest } = useContext(ExploreContext);
  const [stateList, setStateList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [userLocation, setUserLocation] = useState({
    state: "",
    location: "",
  });

  const [locationDetailsAddress, setLocationDetailsAddress] = useState({});
  const [showLocationBtn, setShowLocationBtn] = useState(false);

  const { globalLocationId, setGlobalLocationId } =
    useContext(LocationDataContext);

  // State variable to track whether the user clicked on "Change Location"
  const [isChangeLocationClicked, setIsChangeLocationClicked] = useState(false);

  const handleGetLocationBasedStates = async () => {
    try {
      const response = await GetLocationBasedStates()
      if (response?.statusCode === 200) {
        // Map the state data and set it to the setStateList
        const stateData = response?.data?.Items.map((item) => {
          return { label: item?.state, value: { name: item?.state } };
        });

        setStateList(stateData);
      }
    } catch (error) {
      console.error(error.message);
      return error;
    }
  };

  useEffect(() => {
    handleGetLocationBasedStates();
  }, []);

  const handleToggleChangeLocation = () => {
    // Toggle the isChangeLocationClicked state
    setIsChangeLocationClicked(
      (prevIsChangeLocationClicked) => !prevIsChangeLocationClicked
    );
  };

  useEffect(() => {
    GetUpdatedLocationDetails();
  }, []);

  const GetUpdatedLocationDetails = async () => {
    let franchiseeId;
    let locationId;
    if (userLocation?.location) {
      franchiseeId = encodeURIComponent(userLocation?.location?.locationId);
      locationId = encodeURIComponent(userLocation?.location?.franchiseeId);
    } else {
      franchiseeId = encodeURIComponent(getLocalData("franchiseeId"));
      locationId = encodeURIComponent(getLocalData("locationId"));
    }
    try {
      const response = await ApiServices.get(
        franchiseeId !== 'null'
          ? `${Base_URL}locations/getLocationById?franchiseeId=${franchiseeId}&locationId=${locationId}&status=1`
          : `${Base_URL}locations/getLocationById?locationId=${locationId}&status=1`
      );
      if (response?.statusCode === 200) {
        setLocationDetailsAddress(response?.data?.Item);
        setLocalData('locationName', response?.data?.Item?.locationName)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetLocationsList = async (state) => {
    if (state) {
      try {
        const response = await GetLocationsList(state)
        if (response?.statusCode === 200) {
          let LocNames = response?.data?.Items?.map((item) => {
            return {
              label: item?.locationName,
              franchiseeName: item?.franchiseeName,
              franchiseeId: item?.franchiseeId,
              locationId: item.locationId,
              addressLine1: item?.addressLine1,
              addressLine2: item?.addressLine2,
              mobilePhone: item?.phone,
              postalCode: item.postalCode,
              value: { name: item?.locationName },
            };
          });
          setLocationsList(LocNames);
        }
      } catch (error) {
        console.error(error.message);
        return error;
      }
    }
  };
  const handleStateChange = (name, value) => {
    if (value) {
      setUserLocation({
        ...userLocation,
        [name]: value,
      });
      handleGetLocationsList(value.label);
    }
  };

  const handleLocChange = (name, value) => {
    if (value) {
      setUserLocation({
        ...userLocation,
        [name]: value,
      });
    }
  };

  const handleSetUserLocation = async () => {
    if (userLocation?.state && userLocation?.location) {
      try {
        const ID = encodeURIComponent(
          clientDetail?.sortKey || getLocalData("clientId")
        );
        let locationId = encodeURIComponent(getLocalData("locationId"));
        const reqData = {
          currentLocationId: clientDetail?.partitionKey || locationId,
          newLocationId: userLocation?.location.locationId,
          locationName: userLocation?.location?.label,
          franchiseeId: userLocation?.location?.franchiseeId,
          franchiseeName: userLocation?.location?.franchiseeName,
        };
        const response = await ApiServices.put(
          `${Base_URL}client/updateLocation?id=${ID}`,
          reqData
        );
        if (response?.statusCode === 200) {
          updateClientDataOnSetLocation({ email: clientDetail?.email });
          localStorage.setItem("locationName", userLocation?.location?.label);
          localStorage.setItem(
            "franchiseeId",
            userLocation?.location?.franchiseeId
          );
          localStorage.setItem("locationId", userLocation?.location.locationId);
          setGlobalLocationId(userLocation?.location.locationId);
          setTimeout(handleClose, 500);
        }
      } catch (error) {
        console.error(error.message);
        return error;
      }
    }
  };

  useEffect(() => {
    if (clientId && globalLocationId) {
      GetOrderHistory(clientId, globalLocationId).then((response) => {
        if (response?.statusCode === 200) {
          if (response?.data?.Items?.length === 0) {
            setShowLocationBtn(true);
          } else {
            const data = !response?.data?.Items?.some(
              (value, index) => value?.sqaureOrderState === "COMPLETED"
            );

            setShowLocationBtn(data);
          }
        }
      });
    }
  }, []);

  return (
    <Box
      className="locationSidebar"
      sx={{ width: "420px" }}
      role="presentation"
    >
      <IconButton className="closeNotify closeNotify-location-sidebar" onClick={handleClose}>
        <ChevronLeftIcon />
      </IconButton>

      <Box className="locationHead">
        <Box>
          <LocationOnIcon />
          <Typography className="zoomroom-location">
            {clientDetail?.locationName || locationDetailsAddress?.locationName}
          </Typography>
        </Box>

        {(isGuest === true || (isGuest === false && showLocationBtn)) && (
          <Typography
            className="LocChange"
            onClick={handleToggleChangeLocation}
          >
            Change Location
          </Typography>
        )}
      </Box>
      {isChangeLocationClicked && (
        <>
          <Box>
            <Box
              justifyContent="center"
              direction="column"
              className="addPetWrapp appointment-dropdownn locationSelect"
            >
              <Typography className="signup_location_popup_label">
                Select State
              </Typography>
              <Box className="input-item-wrap">
                <CustomDropdown
                  className="list-inputs"
                  value={userLocation.state || globalLocationId.state}
                  placeHolder={"Select Your State"}
                  onChange={handleStateChange}
                  options={stateList}
                  icon={downArrow}
                  name="state"
                />
              </Box>
            </Box>
            <br />
            <Box
              justifyContent="center"
              direction="column"
              className="addPetWrapp appointment-dropdownn locationSelect"
            >
              <Typography className="signup_location_popup_label">
                Select Location
              </Typography>
              <Box className="input-item-wrap">
                <CustomDropdown
                  className="list-inputs"
                  value={userLocation.location}
                  placeHolder={"Select Your Location"}
                  onChange={handleLocChange}
                  options={locationsList}
                  icon={downArrow}
                  name="location"
                />
              </Box>
            </Box>

            <Box mt={3}>
              <CustomButton
                onClick={handleSetUserLocation}
                title={"Update Location"}
                color="#fff"
                backgroundColor="#32B2AC"
                iconJsx={<ChevronRightIcon />}
                fullWidth
                className="book-btn"
                fontSize="18px"
              />
            </Box>
          </Box>
        </>
      )}

      {isGuest ? null : (
        <Box className="main-schedule-box" mt={4}>
          <YourSchedule
            locationBar
            setActiveSteps={setActiveSteps}
            appointmentOptions={appointmentOptions}
            IsError={IsError}
            error={error}
            handleSelectedItems={handleSelectedItems}
            setIsYourSchedule={setIsYourSchedule}
          />
        </Box>
      )}
      <CustomButton
        sx={{ marginTop: isGuest && "32px" }}
        onClick={() => {
          navigate("/explore");
        }}
        title={"Explore Schedule"}
        color="#fff"
        backgroundColor="#32B2AC"
        iconJsx={<ChevronRightIcon />}
        fullWidth
        className="book-btn"
        fontSize="18px"
      />

      <Box className="dirBox">
        <Box>
          <Box>
            <Typography>Location Address</Typography>
            <Typography sx={{ mt: 1 }}>
              <>
                {locationDetailsAddress ? (
                  <>
                    <Box>
                      {locationDetailsAddress?.addressLine1 ? locationDetailsAddress?.addressLine1 + ', ' : ''}
                      {locationDetailsAddress?.addressLine2 ? locationDetailsAddress?.addressLine2 + ', ' : ''}
                    </Box>
                    <Box>
                      {locationDetailsAddress?.city ? locationDetailsAddress?.city + ', ' : ''}
                      {locationDetailsAddress?.state ? locationDetailsAddress?.state + ', ' : ''}
                      {locationDetailsAddress?.postalCode ? locationDetailsAddress?.postalCode : ''}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      {userLocation?.location
                        ? userLocation?.location?.addressLine1
                        : clientDetail?.addressLine1 || ""}
                      ,{" "}
                      {userLocation?.location
                        ? userLocation?.location?.addressLine2
                        : clientDetail?.addressLine2 || ""}
                      ,{" "}
                    </Box>
                    <Box>
                      {userLocation?.location
                        ? userLocation?.location?.city
                        : clientDetail?.city || ""}{" "}
                      {userLocation?.location
                        ? userLocation?.location?.state
                        : clientDetail?.state || ""}
                      {userLocation?.location
                        ? userLocation?.location?.postalCode
                        : clientDetail?.postalCode || ""}
                    </Box>
                  </>
                )}
              </>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography>Location Phone </Typography>
          <Typography sx={{ mt: 1 }}>
            {locationDetailsAddress
              ? locationDetailsAddress?.phone
              : userLocation?.location
                ? userLocation?.location.mobilePhone
                : clientDetail?.mobilePhone}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

import { DateTime } from "luxon";
import moment from "moment";
export const timeDifferenceCalculate = (startTime, endTime) => {
  const convertTo24HourFormat = (time) => {
    const [hour, minute, period] = time?.split(/[: ]/);
    let hours = parseInt(hour, 10);
    const minutes = parseInt(minute, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    }
    return hours * 60 + minutes;
  };
  const startMinutes = convertTo24HourFormat(startTime);
  const endMinutes = convertTo24HourFormat(endTime);
  const timeDifferenceMinutes = endMinutes - startMinutes;
  const hours = Math.floor(timeDifferenceMinutes / 60); // Convert minutes to hours
  const minutes = timeDifferenceMinutes % 60; // Remaining minutes
  if (hours > 0) {
    return `${hours * 60} min`;
  } else {
    return `${Math?.abs(minutes)} min`;
  }
};

export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getCurrentformattedTime = () => {
  let date = new Date();
  let formattedTime = moment(date).format("hh:mm A");
  return formattedTime;
};

export const timeDifferenceCalculates = (startTime, endTime) => {
  const convertTo24HourFormat = (time) => {
    // const hour = time?.split(/[: ]/)?.hour
    // const minute = time?.split(/[: ]/)?.minute
    // const period = time?.split(/[: ]/)?.period
    const [hour, minute, period] = time?.split(/[: ]/);

    let hours = parseInt(hour, 10);
    const minutes = parseInt(minute, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  };

  const startMinutes = convertTo24HourFormat(startTime);
  let endMinutes = convertTo24HourFormat(endTime);

  // Check if end time is earlier than start time, indicating it's on the next day
  if (endMinutes < startMinutes) {
    endMinutes += 24 * 60; // Add 24 hours in minutes to consider the next day
  }

  const timeDifferenceMinutes = endMinutes - startMinutes;
  const hours = Math.floor(timeDifferenceMinutes / 60); // Convert minutes to hours
  const minutesRemaining = timeDifferenceMinutes % 60; // Remaining minutes

  if (hours > 0) {
    if (minutesRemaining > 0) {
      // return `${hours} hours ${minutesRemaining} minutes`;
      return `${Number(hours) * 60 + Number(minutesRemaining)} mins`;
    } else {
      return `${hours} hours`;
    }
  } else {
    return `${minutesRemaining} min`;
  }
};

export const convertDates = (startDate, endDate) => {
  const formatDate = (date) => {
    const parts = date?.split("-");
    return new Date(parts[2], parts[0] - 1, parts[1]);
  };

  const today = new Date();
  const fromDate = formatDate(startDate);
  // const toDate = formatDate(endDate); // Change this date for testing

  let selectedFromDate = fromDate;
  // let selectedToDate = toDate;

  if (today > fromDate) {
    selectedFromDate = today;
  }

  // if (today > toDate) {
  //     // selectedToDate = today;
  // }

  const options = { month: "long", day: "2-digit" };
  const formatDateLabel = (date) => {
    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === tomorrow().toDateString()) {
      return "Tomorrow";
    } else {
      return date.toLocaleDateString("en-US", options);
    }
  };

  const tomorrow = () => {
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    return tomorrowDate;
  };

  const fromDateFormatted = formatDateLabel(selectedFromDate);
  // const toDateFormatted = formatDateLabel(selectedToDate);
  return fromDateFormatted;
};
export const filtersQuery = (filters) => {
  let filtersParams = JSON.parse(JSON.stringify(filters));
  Object.keys(filtersParams).forEach((key) => {
    if (
      filtersParams[key] === null ||
      filtersParams[key] === "" ||
      filtersParams[key] === "undefined" ||
      filtersParams[key] === undefined ||
      filtersParams[key] === false
    ) {
      delete filtersParams[key];
    }
  });

  let queryString = Object.keys(filtersParams)
    .map((key) => key + "=" + encodeURIComponent(filtersParams[key]))
    .join("&");

  return queryString;
};

export function convertDateFormatManually(dateString) {
  let newDateString = dateString?.replace(/ +(?=AM|PM)/g, ' ');
  const [datePart, timePart] = newDateString?.split(',');
  const [month, day, year] = datePart.split('/');
  const formattedDatePart = [month.padStart(2, '0'), day.padStart(2, '0'), year].join('-');
  const formattedDateString = `${formattedDatePart},${timePart}`;
  return formattedDateString;
}
// Method to convert date in MM-DD-YYYY format when pass to API
export const dateFormat = (inputDate, type) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();

  month = type ? newDate.getMonth() + 2 : newDate.getMonth() + 1;
  if (month > 12) {
    month = 1;
    year = newDate.getFullYear() + 1;
  } else {
    year = newDate.getFullYear();
  }

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${month}-${date}-${year}`;
};
export const maskCardNumber = (cardNumber, full) => {
  const cardNumberStr = cardNumber.toString();
  const last4Digits = cardNumberStr.slice(-4);
  const remainingDigits = full ? cardNumberStr : cardNumberStr.slice(0, -4);

  const maskedDigits = remainingDigits
    .replace(/\d{4}(?=.)/g, "$&-")
    .replace(/\d/g, "x");
  if (full) {
    return maskedDigits;
  }

  return `${maskedDigits}-${last4Digits}`;
};
export const dateFormatWithSlash = (inputDate) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();
  month = newDate.getMonth() + 1;
  year = newDate.getFullYear();
  date = date.toString().padStart(2, "0");
  month = month.toString().padStart(2, "0");
  return `${month}/${date}/${year}`;
};
export const CalenderDateFormat = (inputDate) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();
  month = newDate.getMonth() + 1;
  year = newDate.getFullYear();
  date = date.toString().padStart(2, "0");
  month = month.toString().padStart(2, "0");
  return `${month}-${date}-${year}`;
  // return `${year}-${month}-${date}`;
};
export const setLocalData = (keyName, keyValue) => {
  localStorage.setItem(keyName, keyValue);
};

// get data from the local storage
export const getLocalData = (keyName) => {
  return localStorage.getItem(keyName);
};

// clear local data from the local storage
export const clearLocalData = () => {
  localStorage.clear();
};

export function convertToUTC(inputDate) {
  // Split the input date and time
  const [dateString, timeString] = inputDate?.split(",");

  // Parse the date string into parts (month, day, year)
  const [, month, day, year] = dateString.match(/(\d+)\/(\d+)\/(\d+)/);

  // Parse the time string to get the hours and minutes
  // const [, hours, minutes, ampm] = timeString.match(/(\d+):(\d+)\s+(AM|PM)/);
  const [, hours, minutes, ampm] = timeString
    ? timeString.toUpperCase().match(/(\d+):(\d+)\s+(AM|PM)/)
    : "00:00";
  // Create a Date object for the given date and time in UTC
  const utcDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes)
    )
  );

  // Adjust the UTC date if the time is in PM (and not 12 PM)
  if (ampm === "PM" && hours !== "12") {
    utcDate.setUTCHours(utcDate.getUTCHours() + 12);
  } else if (ampm === "AM" && hours === "12") {
    // If it's 12 AM, subtract one day (24 hours) to change the date
    utcDate.setUTCDate(utcDate.getUTCDate() - 1);
  }

  // Get the user's current timezone dynamically
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC date to the user's timezone
  const options = {
    timeZone: userTimeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const formattedDate = utcDate.toLocaleString("en-US", options);

  return formattedDate;
}

// export  function convertUtcToUserTimeEdit(utcDateTimeString) {
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     // Adjust the format to match your input format (no space after date, space before AM/PM)
//     const formattedInput = utcDateTimeString?.replace(/\s+/g, ''); // Remove all spaces
//     const adjustedFormat = 'M/d/yyyy,h:mma';

//     const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
//       zone: 'utc'
//     });

//     if (!utcDate.isValid) {
//       return 'Invalid UTC DateTime'; // Handle invalid input
//     }

//     const userDate = utcDate.setZone(userTimeZone);

//     if (!userDate.isValid) {
//       return 'Invalid User DateTime'; // Handle invalid conversion
//     }

//     // Format the date as '10/05/2023'
//     const formattedDate = userDate.toFormat('MM/dd/yyyy');

//     // Format the time as '8:00 PM'
//     const formattedTime = userDate.toFormat('hh:mm a');

//     return {
//       formattedDate,
//       formattedTime
//     };
//   }

export const formatDate = (dateString) => {
  const options = { weekday: "long", day: "numeric", month: "short" };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getMonthDate = (inputDate) => {
  let date;
  let month;
  let newDate = new Date(inputDate);
  date = newDate.getDate();
  month = newDate.getMonth() + 1;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[month - 1];
  return { date: date, month: monthName };
};

// export function convertUtcToUserTimeEdit(utcDateTimeString) {
//     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     // Adjust the format to match your input format (no space after date, space before AM/PM)
//     const formattedInput = utcDateTimeString?.replace(/\s+/g, ''); // Remove all spaces
//     const adjustedFormat = 'M/d/yyyy,h:mma';

//     const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
//       zone: 'utc'
//     });

//     if (!utcDate.isValid) {
//       return 'Invalid UTC DateTime'; // Handle invalid input
//     }

//     const userDate = utcDate.setZone(userTimeZone);

//     if (!userDate.isValid) {
//       return 'Invalid User DateTime'; // Handle invalid conversion
//     }

//     // Format the date as '10/05/2023'
//     const formattedDate = userDate.toFormat('MM/dd/yyyy');

//     // Format the time as '8:00 PM'
//     const formattedTime = userDate.toFormat('hh:mm a');

//     return {
//       formattedDate,
//       formattedTime
//     };
//   }

export const convertUTCToUserTime = (utcTime) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDateTime = DateTime.fromFormat(utcTime, "h:mm a", { zone: "UTC" });
  const userTime = utcDateTime.setZone(timeZone);
  return userTime.toFormat("hh:mm a");
};

export const convertTimeToUTC = (timeString) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTime = DateTime.fromFormat(timeString, "h:mm a", {
    zone: timeZone,
  });
  const utcTime = userTime.toUTC();
  return utcTime.toFormat("hh:mm a");
};

export const enterNumberOnly = (value) => {
  value = value.replace(/[^0-9.]/g, "");
  return value;
};
export function convertToUTCTime(inputDate) {
  const [dateString, timeString] = inputDate.split(",");

  // Parse the date string into parts (year, month, day)
  const [, year, month, day] = dateString.match(/(\d+)-(\d+)-(\d+)/);

  // Parse the time string to get the hours and minutes
  const [, hours, minutes, ampm] = timeString
    .toUpperCase()
    .match(/(\d+):(\d+)\s+(AM|PM)/);

  // Create a Date object for the given date and time in UTC
  const utcDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes)
    )
  );

  // Adjust the UTC date if the time is in PM (and not 12 PM)
  if (ampm === "PM" && hours !== "12") {
    utcDate.setUTCHours(utcDate.getUTCHours() + 12);
  } else if (ampm === "AM" && hours === "12") {
    // If it's 12 AM, subtract one day (24 hours) to change the date
    utcDate.setUTCDate(utcDate.getUTCDate() - 1);
  }

  // Get the user's current timezone dynamically
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC date to the user's timezone
  const options = {
    timeZone: userTimeZone,
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const option = { timeZone: userTimeZone, day: "numeric", month: "long" };
  const formattedDate = utcDate.toLocaleString("en-US", options);
  const formattedDateAndMonth = utcDate.toLocaleString("en-US", option);
  return {
    formattedDate,
    formattedDateAndMonth,
  };
}
export function convertToUTCForSlash(inputDate) {
  // Split the input date and time
  const [dateString, timeString] = inputDate.split(",");

  // Parse the date string into parts (month, day, year)
  const [, month, day, year] = dateString.match(/(\d+)\/(\d+)\/(\d+)/);

  // Parse the time string to get the hours and minutes
  // const [, hours, minutes, ampm] = timeString.match(/(\d+):(\d+)\s+(AM|PM)/);
  const [, hours, minutes, ampm] = timeString
    ? timeString.toUpperCase().match(/(\d+):(\d+)\s+(AM|PM)/)
    : "00:00";
  // Create a Date object for the given date and time in UTC
  const utcDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes)
    )
  );

  // Adjust the UTC date if the time is in PM (and not 12 PM)
  if (ampm === "PM" && hours !== "12") {
    utcDate.setUTCHours(utcDate.getUTCHours() + 12);
  } else if (ampm === "AM" && hours === "12") {
    // If it's 12 AM, subtract one day (24 hours) to change the date
    utcDate.setUTCDate(utcDate.getUTCDate() - 1);
  }

  // Get the user's current timezone dynamically
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC date to the user's timezone
  const options = {
    timeZone: userTimeZone,
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = utcDate.toLocaleString("en-US", options);

  return formattedDate;
}

// pull values from the query string 
export const getQueryParam = (searchParams) => {
  let searchParam = searchParams.get("locationId");
  searchParam = searchParam && searchParam === 'LOC' ? `${searchParam}${window.location.hash}` : searchParam;
  return searchParam;
};
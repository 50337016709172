import { API_URL_3, API_URL_2 } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";
import { useContext } from "react";
import { LocationDataContext } from "../../App";

const Base_URL = API_URL_3;
const Base_URL_2 = API_URL_2;

const locationId = encodeURIComponent(getLocalData("locationId"));
const clientId = encodeURIComponent(getLocalData("clientId"));

export const GetAllBundles = async (globalLocationId) => {
  const location =
    encodeURIComponent(getLocalData("locationId")) || globalLocationId;
  try {
    return await ApiServices.get(`${Base_URL}bundles?locationId=${location}&status=1`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetBundleDetail = async (payload, globalLocationId) => {
  const bundleId = encodeURIComponent(payload.bundleId);
  const location =
    encodeURIComponent(getLocalData("locationId")) || globalLocationId;
  try {
    return await ApiServices.get(
      `${Base_URL}bundles/getBundleDetails?bundleId=${bundleId}&locationId=${location}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllMemberships = async (globalLocationId) => {
  const location =
    encodeURIComponent(getLocalData("locationId")) || globalLocationId;
  try {
    return await ApiServices.get(
      `${Base_URL}memberships/allMembership?locationId=${location}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetMembershipDetail = async (payload, globalLocationId) => {
  const membershipId = encodeURIComponent(payload.sortKey);
  try {
    return await ApiServices.get(
      `${Base_URL}memberships/membershipDetail?locationId=${globalLocationId}&id=${membershipId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetPurchaseServices = async (globalLocationId) => {
  // prev url api - services/getAllServices?locationId=
  // const location = encodeURIComponent(globalLocationId) || encodeURIComponent(getLocalData("locationId"));
  const location = encodeURIComponent(getLocalData("locationId")) || globalLocationId;
  try {
    return await ApiServices.get(
      `${Base_URL}pricing-options/getPricingOptions?locationId=${location}&status=1`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetPurchaseServiceDetail = async (payload, globalLocationId) => {
  const serviceId = encodeURIComponent(payload.sortKey);
  try {
    return await ApiServices.get(
      `${Base_URL}services/getServiceDetails?locationId=${globalLocationId}&serviceId=${serviceId}`
      // `${Base_URL}services/getAllServiceCategories?locationId==${locationId}&id=${serviceId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetOrderHistory = async (clientId, globalLocationId) => {
  const client_id = encodeURIComponent(clientId);
  const location = encodeURIComponent(getLocalData("locationId"));
  try {
    return await ApiServices.get(
      `${Base_URL}checkout/getOrderHistory?locationId=${location}&clientId=${client_id}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetPromocodeCetails = async (data) => {
  try {
    return await ApiServices.post(
      `${Base_URL}checkout/getPromoCodeDetails`,
      data
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetMemberShipDiscounts = async (payloadData) => {
  try {
    return await ApiServices.post(
      `${Base_URL}checkout/applyMemberShipDiscounts`,
      { ...payloadData }
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetPurchaseHistoryDetail = async (payload, globalLocationId) => {
  const orderId = encodeURIComponent(payload.sortKey || payload?.title);
  try {
    return await ApiServices.get(
      `${Base_URL}checkout/paymentOrderDetail?locationId=${globalLocationId}&orderId=${orderId}`
      // `${Base_URL}services/getAllServiceCategories?locationId==${locationId}&id=${serviceId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllFeaturedItems = async (globalLocationId) => {
  const locationID = encodeURIComponent(getLocalData("locationId"));
  try {
    return await ApiServices.get(
      `${Base_URL_2}featured-items/getAllFeaturedItems?locationId=${locationID || globalLocationId
      }&status=1`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const EmailPaymentReceipt = async (location, orderId) => {
  try {
    return await ApiServices.get(
      `${Base_URL}checkout/orderEmailReceipt?locationId=${location}&orderId=${orderId}`,
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllCreditAvailability = async (locationId, clientId) => {
  const requestObj = {
    locationId: locationId,
    clientId: clientId,
  };
  try {
    return await ApiServices.post(
      `${Base_URL}checkout/getAllCreditAvailability`,
      requestObj
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetFeaturedItemDetail = async (payload, globalLocationId) => {
  const featuredId = encodeURIComponent(payload?.sortKey);
  const location =
    globalLocationId || encodeURIComponent(getLocalData("locationId"));

  try {
    return await ApiServices.get(
      `${Base_URL_2}featured-items/getFeaturedItemByID?locationId=${location}&id=${featuredId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const CalculateFinalOrder = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}checkout/calculateOrder`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const ValidateGiftCard = async (payload) => {
  try {
    return await ApiServices.get(
      `${Base_URL}gift-cards/isCardExists?locationId=${encodeURIComponent(
        payload?.locationId
      )}&giftCardNumber=${payload?.giftCard}`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const ApplyGiftCardDiscount = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}checkout/applyGiftCardDiscount`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

// CheckoutPlaceOrder-----------------------------
export const CheckoutPlaceOrder = async (payload) => {
  try {
    return await ApiServices.post(`${Base_URL}checkout/placeOrder`, payload);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

// Get Saved card List-------------------
export const GetSavedCardList = async (payload) => {
  try {
    return await ApiServices.get(
      `${Base_URL}checkout/getCardList?customerId=${payload}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
// CheckoutCreatePayment---------------------------
export const CheckoutCreatePayment = async (payload) => {
  // CARD SAVE -TRUE/FALSE
  try {
    return await ApiServices.post(`${Base_URL}checkout/createPayment`, payload);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

// GetPaymentDetails---------------------------
export const GetPaymentDetails = async (payload) => {
  try {
    return await ApiServices.get(
      `${Base_URL}checkout/paymentDetails?locationId=${payload?.locationId}&sqaureOrderId=${payload?.sqaureOrderId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetCartItems = async (payload) => {
  try {
    return await ApiServices.get(
      `${Base_URL}cart/getCartItem?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&clientId=${encodeURIComponent(getLocalData("clientId"))}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const AddToCartItems = async (payload) => {
  try {
    return await ApiServices.post(
      `${Base_URL}cart/addItemToCart?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&clientId=${encodeURIComponent(getLocalData("clientId"))}`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const RemoveCartItems = async (id, locID, cliID) => {
  const itemId = encodeURIComponent(id);

  try {
    return await ApiServices.delete(
      `${Base_URL}cart/removeItemFromCart?locationId=${encodeURIComponent(
        locID
      )}&clientId=${encodeURIComponent(cliID)}&itemId=${itemId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const RemoveAllCartItems = async (locID, cliID) => {
  try {
    return await ApiServices.delete(
      `${Base_URL}cart/deleteCart?locationId=${encodeURIComponent(
        locID
      )}&clientId=${encodeURIComponent(cliID)}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

import axios from "axios";
// import { getLocalData } from "../Utils";
// const accessToken = getLocalData("token")
// const headers = {
//   headers: {
//     'Authorization': `Bearer ${accessToken}`
//   }
// }
// const authHeader = accessToken && headers

export const ApiServices = {
  // Function to make a GET request
  get: async (url) => {
    try {
      const response = await axios.get(url);
      // authHeader
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  // Function to make a POST request
  post: async (url, data) => {
    try {
      const response = await axios.post(url, data);
      // authHeader
      return response.data;
    } catch (error) {
      // throw new Error(error.message);
      return error.response;
    }
  },

  // Function to make a PUT request
  put: async (url, data) => {
    try {
      const response = await axios.put(url, data);
      // authHeader
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  // Function to make a DELETE request
  delete: async (url) => {
    try {
      const response = await axios.delete(url);
      // authHeader
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

import React, { useEffect, useMemo, useState, useContext } from "react";

import { Avatar, Box, Divider, InputLabel, Typography } from "@mui/material";
import {
  CustomButton,
  CustomDropdown,
  CustomLoader,
} from "../../Components/Common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { convertDateFormatManually, getLocalData, setLocalData, timeDifferenceCalculates } from "../../Utils";
import { GetAllPets } from "../../Services/APIs";
import moment from "moment";
import { ExploreContext } from "../../App";
import RegisterPop from "../../Components/Common/RegisterPop";
import { getPetsTrophies } from "../../Services/APIs";
import { getAllServices, getClientProfileProgress } from "../../Services/APIs/client";
import { useNavigate } from "react-router-dom";
import { getValidatePetEligibility } from "../../Services/APIs/appointments";
import { CustomDialogue } from "../../Components/Modals";

const downArrow = require("../../assets/images/dropdownArrow.svg").default;
const banner = require("../../assets/images/bannerBookService.png");

export default function BookService({
  handleNext,
  selected,
  selectedOption,
  setSelectedOption,
  setBookingType,
  setAllScheduleRoute,
  isWaitlist
}) {
  let navigate = useNavigate()
  let showSuccessMsg = getLocalData("CompleteProfileProceed")
  const { isGuest } = useContext(ExploreContext);
  const [open, setOpen] = useState(false);
  const [serviceDetail, setServiceDetail] = useState(selected?.[0] || {});
  const [dogList, setDogList] = useState([]);
  const [attendence, setAttendence] = useState([]);
  const [isDogSelect, setIsDogSelect] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [trophys, setTrophys] = useState([]);
  const [trophyExist, setTrophyExist] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [prerequisites, setPrerequisites] = useState("");
  const [isOutdated, setIsOutdated] = useState(false)
  const [clientProgess, setClientProgess] = useState('')
  const [clientProgressPopup, setClientProgressPopup] = useState(false)

  useEffect(() => {
    if (selected?.[0]) {
      // localStorage.removeItem('redirectionRoute')
      setServiceDetail(selected?.[0])
    }

    if (selected?.[0]?.toDate) {
      const currentDate = new Date()
      const lastDate = new Date(selected?.[0]?.toDate);
      if (lastDate < currentDate) {
        setIsOutdated(true)
      } else if (lastDate?.toDateString() === currentDate?.toDateString()) {
        if (lastDate.getTime() < currentDate.getTime()) {
          setIsOutdated(true)
        } else {
          setIsOutdated(false)
        }
      } else {
        setIsOutdated(false)
      }
    }

  }, [selected])

  useEffect(() => {
    getClientProfileProgressDetails();
  }, []);

  // getclient-progress
  const getClientProfileProgressDetails = () => {
    getClientProfileProgress().then((response) => {
      if (response?.statusCode === 200) {
        const data = response?.data;
        setClientProgess(Number(data?.clientProfileProgress));
        // setClientProgess(Number(0.2));
      }
    });
  };

  const handleProfileComplete = () => {
    setLocalData("CompleteProfileProceed", true)
    navigate("/profile", { state: { redirect: "edit" } })
  }

  // const handlePetEligibilityCheck = async () => {
  //   const requestObj = {
  //     "locationId": serviceDetail?.locationId,
  //     "fromDate": convertDateFormatManually(serviceDetail?.fromDate),
  //     "toDate": convertDateFormatManually(serviceDetail?.toDate),
  //     "clientChildId": selectedOption?.dog?.value?.sortKey,
  //     "clientParentId": selectedOption?.dog?.value?.partitionKey
  //   }
  //   const response = await getValidatePetEligibility(requestObj)
  //   if (response?.statusCode === 200 && response?.data?.isExist) {
  //     setIsDogSelect("Selected pet is already enrolled in this time slot.")
  //   }
  // }

  let encodedLocationId = encodeURIComponent(getLocalData("locationId"));

  const handleDropdownChange = (name, value) => {
    getPetTrophy(value);
    setSelectedOption({ ...selectedOption, [name]: value });
    setIsDogSelect("");
  };
  let payload = useMemo(
    () => ({
      locationId: getLocalData("locationId"),
      occurrenceId: selected?.sortKey,
    }),
    [selected?.sortKey]
  );

  useEffect(() => {
    setServiceDetail(selected?.[0]);
    setAllScheduleRoute(false);
    GetAllPets().then(async (response) => {
      if (response) {
        const data = await response?.data?.Items?.map((item) => ({
          label:
            capitalizeWords(item.firstName) ||
            "" + capitalizeWords(item.lastName) ||
            "",
          value: item,
        }));
        setDogList(data);
      }
    });
    let tempAttendence = selected[0]?.clientDetails?.filter((child, index) => child?.status === "booked" || child?.status === "Booked")
    setAttendence(tempAttendence);
  }, [selected, payload]);

  useEffect(() => {
    if (dogList?.length > 0) {
      const petExists = attendence?.some((attendanceItem) => {
        return (
          // selectedOption?.dog?.value?.sortKey === attendanceItem?.clientChildId
          selectedOption?.dog?.value?.sortKey === attendanceItem?.clientChildId && (attendanceItem?.status === "Booked" || attendanceItem?.status === "booked")
        );
      });

      if (petExists) {
        setIsDogSelect("You have already enrolled a class for this pet. Please select another pet.");
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [selectedOption.dog, attendence, dogList]);

  // useEffect(() => {
  //   if (selectedOption?.dog?.value?.sortKey) {
  //     handlePetEligibilityCheck()
  //   }
  // }, [selectedOption?.dog?.value?.sortKey])

  function capitalizeWords(str) {
    const words = str?.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  const handleCheckOut = () => {
    setLocalData("CompleteProfileProceed", false)
    if (Number(clientProgess) < 1) {
      setClientProgressPopup(true);
    } else {
      if (isGuest) {
        setOpen(true);
      } else if (selectedOption?.dog?.label) {
        setIsDogSelect("");
        setBookingType("Enrollment");
        handleNext();
      } else {
        setIsDogSelect("This field is required.");
      }
    }
  };

  // PREREQUISITE CODE STARTS --------
  useEffect(() => {
    let foundService = null;

    serviceData?.forEach((item) => {
      if (item.sortKey === serviceDetail.serviceId) {
        foundService = item;
      }
    });

    setPrerequisites(foundService?.prerequisites);
  }, [serviceData]);

  useEffect(() => {
    let trophyIdsInDataset = trophys?.map((item) => item.trophyId);

    let extractedIds = Array.isArray(prerequisites)
      ? prerequisites.map((item) => item.id)
      : [];

    const found = extractedIds?.some(item => trophyIdsInDataset.includes(item));
    if (extractedIds && extractedIds?.length > 0 && found) {
      setTrophyExist("");
    } else {
      if (selectedOption?.dog?.label && extractedIds?.length) {
        setTrophyExist("This dog does not meet the prerequisites to enroll in this class.");
        setIsDisabled(true);
      }
    }
  }, [trophys]);

  useEffect(() => {
    if (isDogSelect || trophyExist) {
      setIsDisabled(true);
    }
  }, [isDogSelect, trophyExist])

  const getServicesBasedOnCategory = async () => {
    let categoryId = encodeURIComponent(serviceDetail?.categoryId);
    const response = await getAllServices(categoryId, encodedLocationId);

    if (!response?.error) {
      setServiceData(response.data?.Items);
    } else {
      console.error('Error fetching pet trophies:', response.data?.error);
    }
  };


  useEffect(() => {
    if (serviceDetail?.categoryId) {
      getServicesBasedOnCategory();
    }
  }, [serviceDetail?.categoryId]);

  // useEffect(() => {

  //   if (selectedOption?.dog?.value?.sortKey) {


  //   }
  // }, [selectedOption.dog, attendence, dogList]);

  const getPetTrophy = async (value) => {
    let petId = value?.value?.sortKey ?? "";
    const response = await getPetsTrophies(petId);
    if (!response?.error) {
      setTrophys(response?.data?.Items);
    } else {
      console.error('Error fetching pet trophies:', response.data?.error);
    }
  };

  const handleNavigateToPetProfile = (pet) => {
    localStorage.setItem("prevScreen", "explore")
    navigate("/profile", { state: { isAttendieProfile: true, attendieData: pet } })
  }

  return (
    <Box className="confirm-booking-main">
      <CustomLoader IsLoading={loader} />
      <CustomDialogue
        type="progressCheckout"
        open={clientProgressPopup}
        className={"checkoutModal"}
        handleClose={() => setClientProgressPopup(false)}
        fullWidth
        handleNext={() => {
          setClientProgressPopup(false);
          navigate("/profile", { state: { redirect: "edit" } });
        }}
      />
      <RegisterPop
        open={open}
        setOpen={setOpen}
        messege="In order to add this service, you will have to create your profile, Do you want to create now ?"
      />
      <Box className="display-booking-wrap book-service-left">
        <Box className="img-section">
          <img src={banner} alt="section" className="service-img" />
          <Box className="overlay-content">
            <Box className="header">
              <Typography>{serviceDetail?.categoryName}</Typography>
              {/* <Typography>Next Session</Typography> */}
            </Box>
            <Box className="header" sx={{ gap: "20px" }}>
              <Box className="img-footer">
                <Typography className="heading">
                  {serviceDetail?.serviceName}
                </Typography>
                <Box className="rating-section"></Box>
              </Box>

              <Box className="img-footer">
                <Typography className="heading">
                  {moment(serviceDetail?.fromDate).format("MMMM D")}
                </Typography>
                {serviceDetail?.startTime && serviceDetail?.endTime &&
                  <Typography>
                    {serviceDetail?.startTime?.replace(/\s+/g, " ")} to{" "}
                    {serviceDetail?.endTime?.replace(/\s+/g, " ")} (
                    {timeDifferenceCalculates(
                      serviceDetail?.startTime?.replace(/\s+/g, " "),
                      serviceDetail?.endTime?.replace(/\s+/g, " ")
                    )}
                    )
                  </Typography>
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="booking-notes service-description">
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography className="heading">Description</Typography>
            {isWaitlist ?
              <Typography color={"red !important"} fontFamily={"Univers LT Std"} fontWeight={"700 !important"}>On Waitlist</Typography>
              : null
            }
          </Box>
          <Typography className="description">
            {serviceDetail?.description}
          </Typography>
        </Box>
      </Box>
      <Box className="booking-notes-wrap book-term">
        <Box className="booking-notes service-wrap">
          <Box className="first-section">
            <Avatar
              src={
                serviceDetail?.trainerProfileImage
                  ? serviceDetail?.trainerProfileImage
                  : "https://s3-alpha-sig.figma.com/img/f53e/1d24/fbad79be67f30faaa84b663526fc7319?Expires=1691971200&Signature=nHza3YVjuzDLlFNLw8dhzKEENSJe6NpGGeXc29FxHSUazhzzBZBnBgeaB~NpFhjIUkfnhPwN5ji281JrGHY1XQfgzFEX4Q60iIV-cdmsi6KOW4dEpVkT8t5SNguksFFJtby9QqnZZSH-~UTrquYPjlyqL5PM7wGfDsf-eDcm9fkFFKo6Js-KAOI0qDkUHq2ScO93gO1kRjZ0fs5OniFysumuVICMbzEHh03xBFL26gum7l4aPuOJUSaeVWFfu5JQpPMillOv6B3ZtV5Vtqfj7O7K76btJsJ6ZjksvnStZavnHBB9LEIc8AIITzvRPy-XfUstiPciilad4joeORindQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
              }
            />
            <Box className="display-attendee" sx={{ width: "100%" }}>
              <Box className="attendee-header">
                <Typography>Trainer</Typography>
              </Box>
              <Box className="attendee-value">
                <Typography width={{ xs: "100%", sm: "72%" }}>{serviceDetail?.trainerName}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Typography className="custom-heading">Attendees</Typography>
          <Box className="avatar-list" pb={3}>
            {attendence &&
              attendence.map((item, i) => (
                <>
                  <Avatar src={item.clientChildProfile} sx={{ cursor: "pointer" }} onClick={() => handleNavigateToPetProfile(item)} key={i} />
                  {/* dont remove this {item?.status === "Booked" || item?.status === "booked" ? <Avatar src={item.clientChildProfile} /> : null} */}
                </>
              ))}
            <Typography>
              {attendence?.length > 7 ? "+" + (attendence?.length - 7) : ""}
            </Typography>
          </Box>
        </Box>

        <Box className="appointment-dropdown book-service">
          {!isGuest && <InputLabel>Select Your Pet</InputLabel>}
          {!isGuest && (
            <CustomDropdown
              placeHolder="Select Your Pet"
              value={selectedOption.dog}
              onChange={handleDropdownChange}
              options={dogList}
              icon={downArrow}
              fullWidth
              name={"dog"}
              error={isDogSelect || trophyExist}
              helperText={
                <span> {isDogSelect || trophyExist} </span>
              }
            />
          )}
        </Box>
        {!isGuest ? <>
          {clientProgess < 1 ? <Typography onClick={handleProfileComplete} className="link-text">
            Click here to complete your profile prior to booking the services.
          </Typography> : <Typography className="">
            {showSuccessMsg === "true" ? 'Your profile is completed successfully and you can book your services now.' : null}
          </Typography>}
        </> : null}

        <CustomButton
          className="book-btn"
          title={"Book"}
          color="#fff"
          disabled={isDisabled || isDogSelect || clientProgess < 1}
          backgroundColor="#32B2AC"
          iconJsx={<ChevronRightIcon />}
          fullWidth
          onClick={handleCheckOut}
        />
      </Box>
    </Box>
    // <Typography>Hello{JSON.stringify(selected)}</Typography>
  );
}

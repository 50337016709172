import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { GetAllBuyCredits } from "../../Services/APIs/checkout";

export default function BuyCredit({ handleBack, selected, setCreditBuy, selectedOption, setSelectedOption }) {

    const [creditList, setCreditList] = useState([])
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        let payload;
        if (selected.categoryId && selected.serviceId) {
            payload = selected;
        } else {
            payload = {
                categoryId: selected[0].categoryId,
                serviceId: selected[0].serviceId
            }
        }
        GetAllBuyCredits(payload).then((response) => {
            setCreditList(response?.data?.Items);
            setLoader(false)
        })
    }, [selected])

    const buyCredit = (item) => {
        setSelectedOption({ ...selectedOption, creditsAvailiable: true })
        setSelectedOption({ ...selectedOption, creditsDetails: item })
        setCreditBuy(true);
        handleBack()
    }
    return (
        <>
            {loader ?
                <Box className='loader'>
                    <CircularProgress size={60} />
                </Box> :
                <Box className='buy-credit-main'>
                    <Box className='available-credit-list  buy-credit-css' borderRadius={{ xs: "20px", sm: "50px" }}>
                        {creditList?.length > 0 && <Typography className="all-pricing-options-text">All Pricing Options</Typography>}
                        {creditList && creditList.map((item, index) => (
                            <Box onClick={() => buyCredit(item)} className='credit-box' key={index}>
                                <Box className='credit-header'>
                                    <Typography sx={{ fontSize: '16px!important' }}>{item?.name || item.name} - {item?.numberOfSessions} Credits</Typography>
                                </Box>
                            </Box>
                        ))}
                        {creditList?.length === 0 ?
                            <Grid container className="pricing-options-unavailable" >
                                <Typography fontSize={'40px'}>No pricing options are available for this service</Typography>
                            </Grid>
                            :
                            <Box sx={{ width: "50%" }}>
                                {/* <CustomButton
                                    className='book-btn'
                                    title={"Buy Now"}
                                    disabled={!selectedValue}
                                    color='#fff'
                                    backgroundColor='#32B2AC'
                                    iconJsx={<ChevronRightIcon />}
                                    fullWidth
                                    onClick={buyCredit}
                                /> */}
                            </Box>
                        }

                    </Box>
                </Box>
            }
        </>
    );
}

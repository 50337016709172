import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CustomButton } from "../../Components/Common";
import { useContext, useEffect, useState } from "react";
import {
  GetBundleDetail,
  GetFeaturedItemDetail,
  GetMembershipDetail,
  AddToCartItems,
  GetPurchaseHistoryDetail,
  getClientProfileProgress,
} from "../../Services/APIs";
import { CustomDialogue, ProfileModals } from "../../Components/Modals";
import { useNavigate } from "react-router-dom";
import RegisterPop from "../../Components/Common/RegisterPop";
import { ExploreContext } from "../../App";

export default function ItemDetail({
  handleNext,
  selected,
  selectedType,
  setSelectedType,
  handleBack,
  globalLocationId,
  moveToCart,
  getItemLength,
  setSelectedFeaturedItem,
}) {
  const navigate = useNavigate()
  const { isGuest } = useContext(ExploreContext);
  const [detail, setDetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showRegister, setShowRegister] = useState(false);
  const [addToCartPopup, setAddToCartPopup] = useState(false);
  const [clientProgess, setClientProgess] = useState('')
  const [clientProgressPopup, setClientProgressPopup] = useState(false)
  const [cartPopup, setCartPopup] = useState({
    type: "",
    show: false,
  });

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedType === "Check Out") {
      setSelectedType("Featured Items")
    }
  }, [selectedType, setSelectedType])

  useEffect(() => {
    if (selectedType === "Featured Items") {
      GetFeaturedItemDetail(selected, globalLocationId).then((response) => {
        if (response.statusCode === 200) {
          const modifiedData = response.data.Items.map((item) => ({
            list: item?.itemData,
            ...item,
          }));
          setSelectedFeaturedItem(
            response?.data?.Items &&
              response?.data?.Items[0]?.itemType === "Contracts"
              ? "Contracts"
              : response?.data?.Items[0]?.itemData[0]?.bundleName
          );
          setDetail(modifiedData[0]);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    } else if (selectedType === "Bundle") {
      GetBundleDetail(selected, globalLocationId).then((response) => {
        const modifiedData = response.data.Items.map((item) => ({
          list: item.items,
          ...item,
        }));

        setDetail(modifiedData && modifiedData[0]);
        setLoader(false);
      });
    } else if (selectedType === "Purchase Service") {
      setDetail(selected);
      setLoader(false);
      // GetPurchaseServiceDetail(selected, globalLocationId).then((response) => {
      //   const modifiedData = response.data.Items.map((item) => ({
      //     list: item.pricingOptions,
      //     ...item,
      //   }));
      //   setDetail(modifiedData[0]);
      //   setLoader(false);
      // });
    } else if (selectedType === "Purchase History") {
      GetPurchaseHistoryDetail(selected, globalLocationId).then((response) => {
        getItemLength(response.data.Items?.length);
        const modifiedData = response.data.Items
        setDetail(modifiedData);

        setLoader(false);
      });
    } else if (
      selectedType !== "Enrollment" &&
      selectedType !== "Appointment"
    ) {
      GetMembershipDetail(selected, globalLocationId).then((response) => {
        const modifiedData = response.data.Items.map((item) => ({
          list: item.benefits,
          ...item,
        }));
        setDetail(modifiedData[0]);
        setLoader(false);
      });
    }

    if (selectedType === "Enrollment" || selectedType === "Appointment") {
      setLoader(false);
      setDetail(selected);
    }
  }, [selectedType, selected]);

  useEffect(() => {
    getClientProfileProgressDetails();
  }, []);

  // getclient-progress
  const getClientProfileProgressDetails = () => {
    getClientProfileProgress().then((response) => {
      if (response?.statusCode === 200) {
        const data = response?.data;
        setClientProgess(Number(data?.clientProfileProgress));
      }
    });
  };

  const handleCartManager = () => {
    if (Number(clientProgess) < 1) {
      setClientProgressPopup(true);
    } else {
      AddCartItems()
    }
  }


  const AddCartItems = () => {
    var itemVal = detail && detail?.list;
    let payload;

    if (
      selectedType === "Purchase Service" ||
      selectedType === "Enrollment" ||
      selectedType === "Appointment"
    ) {
      payload = {
        item: {
          itemId: detail && detail?.sortKey ? detail?.sortKey : "",
          itemName: detail?.name ? detail?.name : "",
          creditsValidityExpires:
            (detail && detail?.creditsValidityExpires) || {},
          creditsValidityStarts: detail && detail?.creditsValidityStarts,
          endTime: "",
          free: detail?.free ? detail?.free : false,
          itemCategoryId: detail?.categoryId ? detail?.categoryId : "",
          itemCategoryName: detail?.categoryName ? detail?.categoryName : "",
          itemType: "PricingOption",
          numberOfSessions: detail && detail?.numberOfSessions,
          price: (detail && detail?.price) || 0,
          quantity: 1,
          restricted: false,
          schedulingLimitation: detail?.schedulingLimitation,
          sellOnline: detail?.sellOnline,
          services: detail && detail?.services,
          setTimeRange: false,
          sharable: detail?.sharable,
          startTime: "",
          validThroughEndDate: detail?.validThroughEndDate || "",
          validThroughStartDate: detail?.validThroughStartDate || "",
        },
        createdBy: "1",
      };
    } else if (selectedType === "Bundle") {
      payload = {
        createdBy: detail?.createdBy,
        item: {
          actualPrice: detail?.actualPrice,
          discount: detail?.discount,
          endDate: detail?.endDate,
          itemId: detail?.bundleId,
          itemName: detail?.bundleName,
          itemType: "Bundle",
          price: detail?.bundlePrice,
          quantity: 1,
          sellOnline: detail?.sellOnline,
          startDate: detail?.startDate,
        },
      };
    } else if (selectedType === "Featured Items") {
      payload = {
        createdBy: detail?.createdBy,
        item: {
          actualPrice:
            detail && detail?.itemData && detail?.itemData[0]
              ? detail?.itemData[0]?.actualPrice
              : 0,
          discount:
            detail?.discount ||
            (detail?.itemData && detail?.itemData[0]?.discount) ||
            0,
          endDate: detail?.endDate || "",
          itemId:
            detail && detail?.itemData && detail?.itemData[0]
              ? detail?.itemData[0]?.sortKey
              : "",
          itemName:
            detail && detail?.itemData && detail?.itemData[0]
              ? detail?.itemData[0]?.bundleName
              : "",
          itemType:
            detail && detail?.itemType === "Memberships"
              ? "Membership"
              : detail?.itemType === "Bundles"
                ? "Bundle"
                : detail?.itemType,
          price:
            detail && detail?.itemData && detail?.itemData[0]
              ? detail?.itemData[0]?.price || detail?.itemData[0]?.bundlePrice
              : 0,
          quantity: 1,
          sellOnline: detail?.sellOnline || false,
          startDate: detail?.startDate || "",
        },
      };
    }

    AddToCartItems(payload, globalLocationId).then((res) => {
      if (res.statusCode === 200) {
        if (res?.message === "Item already exists in the cart") {
          setCartPopup({ show: true, type: "successCart" });
        } else {
          handleNext();
        }
      } else {
        setAddToCartPopup(true);
      }
    });
  };

  const schedulingWeekDayForPricingOpt = () => {
    if (detail && detail?.schedulingLimitation) {
      return detail?.schedulingLimitation?.weekDays?.map((day, index) => {
        return (
          " " +
          day +
          `${index + 1 === detail?.schedulingLimitation?.weekDays.length
            ? ""
            : ","
          }`
        );
      });
    }
  };

  return (
    <>
      <CustomDialogue
        type="progressAddToCart"
        open={clientProgressPopup}
        className={"checkoutModal"}
        handleClose={() => setClientProgressPopup(false)}
        fullWidth
        handleNext={() => {
          setClientProgressPopup(false);
          navigate("/profile", { state: { redirect: "edit" } });
        }}
      />
      <RegisterPop
        open={showRegister}
        setOpen={setShowRegister}
        messege="In order to add this service, you will have to create your profile, Do you want to create now ?"
      />
      {loader ? (
        <Box className="loader">
          {" "}
          <CircularProgress size={60} />{" "}
        </Box>
      ) : (
        <Box className="item-detail-wrap" gap={{ xs: 1, md: 6 }}>
          <Box className="top-section" mt={{ xs: 2, sm: 0 }}>
            {selectedType === "Purchase Service" ||
              selectedType === "Enrollment" ||
              selectedType === "Appointment" ||
              detail?.itemType === "Contracts" ? (
              <>
                <Typography
                  className="heading"
                  sx={{
                    color:
                      selectedType === "Purchase Service" ||
                        selectedType === "Appointment" ||
                        selectedType === "Enrollment"
                        ? ""
                        : "black !important",
                  }}
                >
                  {selectedType === "Purchase Service" ||
                    selectedType === "Appointment" ||
                    selectedType === "Enrollment"
                    ? "Pricing Option Details"
                    : "Credits used for the services"}
                </Typography>
                {detail?.itemType === "Contracts" ? (
                  <>
                    <Typography className="heading">Description</Typography>
                    <Typography className="detail-item-desc">
                      {detail?.description}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {selectedType !== "Purchase Service" ? (
                  <Typography>
                    {detail?.services && detail?.services?.length
                      ? detail?.services?.map(
                        (service, index) =>
                          `${service?.name}${index === detail?.services?.length - 1 ? "" : ", "
                          }`
                      )
                      : ""}
                  </Typography>
                ) : null}
                <Grid container spacing={2}>
                  {detail?.itemType === "Contracts" ||
                    selectedType === "Purchase Service" ||
                    selectedType === "Enrollment" ||
                    selectedType === "Appointment" ? (
                    <>
                      {selectedType === "Enrollment" ? (
                        <Grid item xs={12}>
                          <Typography className="detail-item-desc">
                            {detail?.categoryName}
                          </Typography>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {selectedType === "Purchase Service" ? (
                        <>
                          <Grid item xs={4}>
                            <Typography className="detail-item-desc">
                              Name{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className="detail-item-desc">
                              {detail?.name}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}

                      <Grid item xs={4}>
                        <Typography className="detail-item-desc">
                          No. of Sessions{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="detail-item-desc">
                          {(detail?.itemData &&
                            detail?.itemData[0]?.items[0]?.numberOfSessions) ||
                            detail?.numberOfSessions}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className="detail-item-desc">
                          Price
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {detail?.free ? (
                          <Typography className="detail-item-desc">
                            FREE
                          </Typography>
                        ) : (
                          <Typography className="detail-item-desc">
                            $
                            {(
                              (detail?.itemData &&
                                (detail?.itemData[0]?.items[0]?.price || 0)) ||
                              detail?.price ||
                              0
                            ).toFixed(2)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className="detail-item-desc">
                          Expires In
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="detail-item-desc">
                          {detail?.creditsValidityExpires?.duration}{" "}
                          {detail?.creditsValidityExpires?.unit}
                        </Typography>
                      </Grid>
                      {detail?.itemType === "Contracts" ? (
                        <>
                          <Grid item xs={4}>
                            <Typography className="detail-item-desc">
                              Pricing
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className="detail-item-desc">
                              {`$${detail?.itemData &&
                                detail?.itemData[0]?.items[0]?.price
                                } ${detail?.itemData &&
                                detail?.itemData[0]?.billingCycle
                                }`}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            ) : selectedType !== "Purchase History" &&
              selectedType !== "Appointment" &&
              selectedType !== "Enrollment" ? (
              <>
                <Typography className="heading">Description</Typography>

                <Typography className="detail-item-desc">
                  {detail?.description || "No description available"}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Box>

          {selectedType === "Membership" && (
            <>
              <Box className="  mid-itemm" style={{ marginBottom: "-3.5em" }}>
                <Typography className="detail-item-desc">Pricing </Typography>

                <Typography
                  className="detail-item-desc"
                  style={{ textAlign: "end" }}
                >
                  ${detail.price} Per Month{" "}
                  <div style={{ marginRight: 9 }}>Auto-Renewal</div>
                </Typography>
              </Box>
            </>
          )}

          <Box
            className="top-section"
            mt={{
              xs:
                selectedType === "Enrollment" || selectedType === "Appointment"
                  ? 4
                  : 0,
              sm: 0,
            }}
          >
            <Typography className="heading">
              {selectedType === "Featured Items" &&
                !detail?.itemType === "Contracts"
                ? "Items Included"
                : selectedType === "Membership"
                  ? "Benefits"
                  : selectedType === "Bundle"
                    ? "Items Included"
                    : selectedType === "Purchase Service" ||
                      selectedType === "Appointment" ||
                      selectedType === "Enrollment"
                      ? "Tailored Access plan for your dog"
                      : selectedType === "Enrollment"
                        ? "Purchase Availability"
                        : selectedType === "Purchase History"
                          ? "Purchase Detail"
                          : ""}
            </Typography>
            {selectedType === "Purchase Service" ||
              selectedType === "Enrollment" ||
              selectedType === "Appointment" ||
              detail?.itemType === "Contracts" ? (
              <>
                <Grid item sm={12} className="item-details">
                  {detail?.itemType === "Contracts" ? (
                    <Typography className="heading" mb={3}>
                      Purchase Availability
                    </Typography>
                  ) : (
                    ""
                  )}
                  {selectedType !== "Purchase Service" &&
                    selectedType !== "Appointment" &&
                    selectedType !== "Enrollment" ? (
                    <Box
                      className="mid-item"
                      sx={{ display: "flex", width: "100%" }}
                    >
                      <Typography
                        className=""
                        sx={{ fontWeight: "700", mr: 2 }}
                      >
                        From :{" "}
                      </Typography>
                      <Typography
                        className="detail-item-desc"
                        mr={{ md: 33, xs: 5 }}
                      >
                        {/* {handleFormatAvailabilityDate(
                        detail?.validThroughStartDate?.replaceAll("-", "/")
                      )} */}
                        {detail?.validThroughStartDate}
                      </Typography>
                      <Typography
                        className=""
                        sx={{ fontWeight: "700", mr: 2 }}
                      >
                        To :{" "}
                      </Typography>
                      <Typography className="detail-item-desc">
                        {/* {handleFormatAvailabilityDate(
                        detail?.validThroughEndDate?.replaceAll("-", "/")
                      )} */}
                        {detail?.validThroughEndDate}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography className="detail-item-desc">
                      {detail?.itemData ? (
                        <>
                          {detail?.itemData[0]?.items[0]?.sharable ? (
                            <>
                              The services included in this pricing option can
                              be used by{" "}
                              <span style={{ fontWeight: 700 }}>
                                all of your connected dogs.
                              </span>
                            </>
                          ) : (
                            <>
                              The services included in this pricing option are
                              restricted to use by{" "}
                              <span style={{ fontWeight: 700 }}>
                                one dog only
                              </span>
                            </>
                          )}
                        </>
                      ) : detail?.sharable ? (
                        <>
                          The services included in this pricing option can be
                          used by{" "}
                          <span style={{ fontWeight: 700 }}>
                            all of your connected dogs.
                          </span>
                        </>
                      ) : (
                        <>
                          The services included in this pricing option are
                          restricted to use by{" "}
                          <span style={{ fontWeight: 700 }}>one dog only</span>
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
                {selectedType !== "Purchase Service" &&
                  selectedType !== "Appointment" &&
                  selectedType !== "Enrollment" ? (
                  <>
                    <Typography className="heading">Usage Lifespan</Typography>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: {
                          sm: "space-evenly",
                          xs: "space-between",
                        },
                      }}
                    >
                      <Grid sm={12} item>
                        <Box className="mid-item" sx={{ display: "flex" }}>
                          <Typography
                            className=""
                            sx={{ fontWeight: "700", mr: 5 }}
                          >
                            Used On{" "}
                          </Typography>
                          <Typography
                            className="detail-item-desc"
                            mr={{ md: 30, xs: 5 }}
                          >
                            {schedulingWeekDayForPricingOpt()}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid sm={12} item>
                        <Box
                          className="mid-item"
                          sx={{ mt: 2, display: "flex" }}
                        >
                          <Typography
                            className=""
                            sx={{ fontWeight: "700", mr: { md: 5, xs: 2 } }}
                          >
                            Starts{" "}
                          </Typography>
                          <Typography
                            className="detail-item-desc"
                            mr={{ md: 30, xs: 2 }}
                          >
                            {detail?.itemType === "Contracts"
                              ? detail?.itemData &&
                              detail?.itemData[0]?.items[0]
                                ?.creditsValidityStarts
                              : detail?.creditsValidityStarts}
                          </Typography>
                          <Typography
                            className=""
                            sx={{ fontWeight: "700", mr: 5 }}
                          >
                            Expires In:{" "}
                          </Typography>
                          <Typography className="detail-item-desc">
                            {detail?.itemType === "Contracts"
                              ? detail?.itemData &&
                              detail?.itemData[0]?.items[0]
                                ?.creditsValidityExpires?.duration
                              : detail?.creditsValidityExpires?.duration}{" "}
                            {detail?.itemType === "Contracts"
                              ? detail?.itemData &&
                              detail?.itemData[0]?.items[0]
                                ?.creditsValidityExpires?.unit
                              : detail?.creditsValidityExpires?.unit}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : detail?.schedulingLimitation?.setWeekDays ? (
                  <>
                    <Typography className="heading">Usage Lifespan</Typography>
                    <Typography className="detail-item-desc">
                      The services included in this pricing option can only be
                      scheduled on{" "}
                      <span style={{ fontWeight: 700 }}>
                        {schedulingWeekDayForPricingOpt()}
                      </span>{" "}
                      {detail?.startTime ? (
                        <>
                          between{" "}
                          <span style={{ fontWeight: 700 }}>
                            {detail?.startTime} to {detail?.endTime}
                          </span>
                        </>
                      ) : null}
                    </Typography>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}
            <Box className="mid-section" sx={{ mb: 5 }}>
              {(selectedType === "Membership" ||
                selectedType === "Bundle" ||
                (selectedType === "Featured Items" &&
                  detail?.itemType !== "Contracts")) && (
                  <>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: {
                          md: "space-evenly",
                          xs: "space-between",
                        },
                      }}
                    >
                      <Grid xs={4} item>
                        <Box>
                          <Typography
                            className="detail-item-desc"
                            sx={{ fontWeight: "700 !important" }}
                          >
                            {"Item Name"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid xs={4} item>
                        <Box className="item-detail-price">
                          <Typography
                            className="detail-item-desc"
                            sx={{ fontWeight: "700 !important" }}
                          >
                            {/* {(detail?.itemData &&
                            detail?.itemData[0]?.items[0]?.itemType ===
                              "Product") ||
                          (detail?.items &&
                            detail?.items[0]?.itemType === "Product")
                            ? "Variants"
                            : "Credits"} */}
                            Variants/Credits
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid xs={4} item>
                        <Box className="item-detail-value">
                          <Typography
                            className="detail-item-desc"
                            sx={{ fontWeight: "700 !important" }}
                          >
                            Price
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {(detail?.itemData && detail?.itemData[0])?.items?.map(
                      (item) => {
                        return (
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: {
                                sm: "space-evenly",
                                xs: "space-between !important",
                              },
                            }}
                          >
                            <Grid xs={4} item>
                              <Box>
                                <Typography className="detail-item-desc">
                                  {item?.itemName}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid xs={4} item>
                              <Box className="item-detail-price">
                                <Typography className="detail-item-desc">
                                  {item?.itemType === "Product" ? (
                                    item?.variants?.map(
                                      (variant, i) =>
                                        variant?.value &&
                                        `${variant?.value}${item?.variants?.length - 1 === i
                                          ? ""
                                          : ", "
                                        }`
                                    )
                                  ) : (
                                    <>
                                      {item?.numberOfSessions}
                                      {item?.numberOfSessions ? " Credits" : ""}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid xs={4} item>
                              <Box className="item-detail-value">
                                <Typography className="detail-item-desc">
                                  ${item?.price?.toFixed(2)}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}

                    {selectedType !== "Bundle" ? (
                      <>
                        <Grid container justifyContent="space-between">
                          <Grid sm={6} item>
                            <Box>
                              <Typography
                                className=""
                                sx={{ fontWeight: "700 !important" }}
                              >
                                {"Discount"}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            sm={6}
                            item
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box>
                              <Typography
                                className="detail-item-desc"
                                sx={{ fontWeight: "700 !important" }}
                              >
                                {detail?.itemData &&
                                  detail?.itemData[0]?.discount}
                                {detail?.itemData && detail?.itemData[0]?.discount
                                  ? "%"
                                  : ""}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid sm={6} item>
                            <Box>
                              <Typography
                                className="detail-item-desc"
                                sx={{ fontWeight: "700 !important" }}
                              >
                                {"Total"}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            sm={6}
                            item
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box>
                              <Typography sx={{ fontWeight: "bold" }}>
                                $
                                {(detail?.itemData &&
                                  detail?.itemData[0]?.bundlePrice?.toFixed(2)) ||
                                  0}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          sx={{ display: { md: "flex", xs: "block" } }}
                        >
                          <Grid sm={12} item>
                            <Typography className="heading" mb={{ md: 3, xs: 1 }}>
                              Valid From
                            </Typography>
                          </Grid>

                          <Grid sm={6} item>
                            <Box>
                              <Typography
                                className=""
                                sx={{ fontWeight: "bold" }}
                              >
                                {"Start Date"}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid sm={6} item>
                            <Box>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {/* {handleFormatAvailabilityDate(
                                detail?.itemData &&
                                  detail?.itemData[0]?.startDate?.replaceAll(
                                    "-",
                                    "/"
                                  )
                              )} */}
                                {detail?.itemData &&
                                  detail?.itemData[0]?.startDate}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          sx={{ display: { md: "flex", xs: "block" } }}
                        >
                          <Grid sm={12} item>
                            <Typography className="heading" mb={{ md: 3, xs: 1 }}>
                              Until
                            </Typography>
                          </Grid>
                          <Grid sm={6} item>
                            <Box>
                              <Typography
                                className=""
                                sx={{ fontWeight: "bold" }}
                              >
                                {"End Date"}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid sm={6} item>
                            <Box>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {/* {handleFormatAvailabilityDate(
                                detail?.itemData &&
                                  detail?.itemData[0]?.endDate?.replaceAll(
                                    "-",
                                    "/"
                                  )
                              )} */}
                                {detail?.itemData && detail?.itemData[0]?.endDate}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}

              {(selectedType === "Membership" ||
                selectedType === "Bundle" ||
                (selectedType === "Featured Items" &&
                  detail?.itemType !== "Contracts" &&
                  detail?.itemType !== "Bundles" &&
                  detail?.itemType !== "Memberships")) &&
                detail &&
                detail?.list?.map((result, index) => (
                  <>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: {
                          sm: "space-evenly",
                          xs: "space-between !important",
                        },
                      }}
                    >
                      <Grid xs={4} item>
                        <Box key={index}>
                          <Typography className="detail-item-desc">
                            {(result?.itemName
                              ? result.itemName
                              : result?.serviceName
                                ? result.serviceName
                                : "") || result?.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={4} item>
                        <Box className="item-detail-price" px={1}>
                          {result?.itemType === "Service" ? (
                            <Typography>
                              {result?.pricingOption?.numberOfSessions
                                ? result?.pricingOption?.numberOfSessions
                                : ""}
                            </Typography>
                          ) : (
                            <Typography className="detail-item-desc">
                              {result?.itemType === "Product"
                                ? result?.variants?.map(
                                  (variant, i) =>
                                    variant?.value &&
                                    `${variant?.value}${result?.variants?.length - 1 === i
                                      ? ""
                                      : ", "
                                    }`
                                )
                                : (result?.numberOfSessions
                                  ? result?.numberOfSessions
                                  : "") ||
                                (result?.benefits &&
                                  result?.benefits[0]?.numberOfSessions)}{" "}
                              {result?.numberOfSessions ? "Credit" : ""}
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid xs={4} item>
                        <Box className="item-detail-value">
                          {/* <Typography >${ result?.pricingOption?.price ? result?.pricingOption?.price : result?.itemValue?.price ? result?.itemValue?.price : '22 session'}</Typography> */}

                          {result?.itemType === "Service" ? (
                            <Typography className="detail-item-desc">
                              $
                              {Number(result?.pricingOption?.price).toFixed(
                                2
                              ) || 0}
                            </Typography>
                          ) : (
                            <Typography className="detail-item-desc">
                              ${Number(result?.price || 0).toFixed(2)}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ))}
              {(selectedType === "Bundle" || selectedType === "Membership") && (
                <>
                  <Box className="mid-item" sx={{ mt: 1 }}>
                    <Typography className="detail-item-desc">
                      Discount{" "}
                    </Typography>
                    <Typography className="detail-item-desc">
                      {detail?.discount ? detail?.discount : 0}%
                    </Typography>
                  </Box>
                  <Box className="mid-item" sx={{ mt: 3 }}>
                    {selectedType === "Membership" ? (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              className="detail-item-desc"
                              sx={{ fontWeight: "700" }}
                            >
                              Item Name{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "end" }}>
                            <Typography
                              className="detail-item-desc"
                              sx={{ fontWeight: "700" }}
                            >
                              {"Discount (%)"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Box className="mid-item" sx={{ mt: 3 }}>
                        <Typography
                          className="detail-item-desc"
                          sx={{ fontWeight: "700" }}
                        >
                          Total{" "}
                        </Typography>
                        <Typography
                          className="detail-item-desc"
                          sx={{ fontWeight: "700" }}
                        >
                          ${(detail?.bundlePrice && detail?.bundlePrice)?.toFixed(2) || 0}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {selectedType !== "Membership" && (
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: {
                          sm: "space-evenly",
                          xs: "space-between !important",
                        },
                        mt: 2,
                      }}
                    >
                      <Grid xs={12} sm={6} item>
                        <Box>
                          <Typography
                            className="heading"
                            sx={{ fontWeight: "bold" }}
                          >
                            {"Valid From"}
                          </Typography>
                        </Box>
                        <Box className="mid-item" sx={{ mt: 2 }}>
                          <Typography
                            className="detail-item-desc"
                            sx={{ fontWeight: "700" }}
                          >
                            Start Date{" "}
                          </Typography>
                          {/* <Typography className=''>{detail?.startDate}</Typography> */}
                          <Typography
                            className="detail-item-desc"
                            sx={{ marginRight: 30 }}
                          >
                            {detail?.startDate ?? ""}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid sm={6} xs={12} item>
                        <Box className="">
                          <Typography
                            className="heading"
                            sx={{ fontWeight: "bold" }}
                            mt={{ xs: 2, sm: 0 }}
                          >
                            {"Until"}
                          </Typography>
                        </Box>

                        <Box className="mid-item" sx={{ mt: 2 }}>
                          <Typography
                            className="detail-item-desc"
                            sx={{ fontWeight: "700" }}
                          >
                            End Date{" "}
                          </Typography>
                          <Typography
                            className="detail-item-desc"
                            sx={{ marginRight: 30 }}
                          >
                            {detail?.endDate}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box className="item-detail-value">
                          <Typography sx={{ fontWeight: "bold" }}></Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}

              <Box className="buy-credit-main">
                <Box className="available-credit-list">
                  {selectedType === "Purchase Service" &&
                    detail &&
                    detail?.list?.map((result, index) => (
                      <Box className="credit-box">
                        <Box className="credit-header-service credit-header">
                          <Typography>
                            {"Puppy"} - {result.name}
                          </Typography>

                          <Typography>
                            {"Valid Through"} -{" "}
                            <span
                              style={{ color: "#003087", fontWeight: "400" }}
                            >
                              {result.validThroughEndDate}
                            </span>
                          </Typography>

                          <Box className="credit-footer">
                            <Typography>Sessions</Typography>

                            <Typography>{result.numberOfSessions}</Typography>

                            <Typography>Price</Typography>

                            <Typography>${result.price}</Typography>
                          </Box>
                        </Box>

                        <Box>
                          <FormControl>
                            <RadioGroup
                              className="radio-main"
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={selectedValue}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value={result.name}
                                control={<Radio />}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>

              {selectedType === "Purchase History" ? (
                <>
                  {selected && selected?.items?.length
                    ? selected?.items?.map((item, index) => {
                      return (
                        <Grid
                          key={index}
                          container
                          sx={{
                            display: "flex",
                            justifyContent: {
                              xs: "space-between",
                              sm: "space-evenly",
                            },
                          }}
                        >
                          <Grid xs={4} item>
                            <Box>
                              <Typography className="detail-item-desc">
                                {item?.itemName}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid xs={4} item>
                            <Box className="item-detail-price">
                              <Typography>
                                {item?.itemType === "PricingOption"
                                  ? item?.numberOfSessions
                                  : item?.quantity}{" "}
                                {item?.numberOfSessions
                                  ? "Credits"
                                  : "Quantity"}
                                {/* {`${item?.numberOfSessions ? item?.numberOfSessions : item?.quantity} ${item?.numberOfSessions ? 'Credit' : ''}`} */}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid xs={4} item>
                            <Box className="item-detail-value">
                              <Typography>${item?.price?.toFixed(2) || 0}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })
                    : null}

                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      className="heading"
                      sx={{ color: "black !important" }}
                    >
                      Total
                    </Typography>
                    <Typography
                      className="heading"
                      sx={{ color: "black !important" }}
                    >
                      ${selected?.totalSquareAmountWithTaxInDollar?.toFixed(2) || 0}
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Box>
          </Box>
          {detail?.itemType === "Contracts" ? (
            <Typography className="heading" textAlign={"center"}>
              Visit your nearest Zoom Room store to buy this contract
            </Typography>
          ) : (
            ""
          )}

          <Box className="footer-section">
            {selectedType === "Item#01" ||
              selectedType === "Purchase History" ||
              detail?.itemType === "Contracts" ? (
              <Box sx={{ width: "100%" }}></Box>
            ) : (
              // selectedType !== "Featured Items" &&
              <CustomButton
                className="book-btn"
                title={"ADD TO CART"}
                color="#fff"
                backgroundColor="#32B2AC"
                iconJsx={<ChevronRightIcon />}
                fullWidth
                //onClick={handleNext}
                onClick={() => isGuest ? setShowRegister(true) : handleCartManager()}
              />
            )}

            {/* {selectedType !== "Enrollment" &&
              selectedType !== "Appointment" && (
                <CustomButton
                  className="book-btn item-detail-btn"
                  title={"BACK TO SHOPPING"}
                  // backgroundColor='#fff'

                  color="#32B2AC"
                  iconJsx={<ChevronRightIcon />}
                  fullWidth
                  onClick={handleBack}
                />
              )} */}
          </Box>
        </Box>
      )}

      <ProfileModals
        open={addToCartPopup}
        type={"Failed"}
        handleActionBtn={() => setAddToCartPopup(false)}
      />

      <ProfileModals
        type={cartPopup?.type}
        open={cartPopup?.show}
        handleClose={handleNext}
      />

      {/* <CustomDialogue
        type={cartPopup?.type}
        open={cartPopup?.show}
        className={"checkoutModal"}
        handleClose={() => setCartPopup({ ...cartPopup, show: false })}
        fullWidth
        handleNext={() => setCartPopup({ ...cartPopup, show: false })}
      /> */}
    </>
  );
}
